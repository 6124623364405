/* navbar link glow */
.nav-link.active { 
  /* color: #8082b1 !important;  */
  text-shadow: 1px 1px 2px black, 0 0 25px #795589, 0 0 5px #530ebb;
}

/* about-site */
.home-link-list {
  overflow: hidden;
  margin: 1em;
  text-align: center;
}

.home-link-list ul {
  list-style: none;
  padding: 0;
  margin-left: -4px;
}

.home-link-list ul li {
  display: inline;
  white-space: nowrap;
}

.home-link-list li:not(:first-child)::before {
  content: "|";
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.home-link-list ul li {
  float: none;
  display: inline-block;
}

.modal-backdrop {
  opacity: .8 !important;
}

.bottom-spacer {
  height: 80px;
}

.inline-action-link {
  text-transform: uppercase;
  font-size: .75em;
  padding-left: .25em;
}

.list-group-item {
  border-color: var(--bs-primary);
}

.card {
  border-color: var(--bs-primary);
}

blockquote.deliveryInstructions {
  border-left: 5px solid BurlyWood;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

blockquote.deliveryInstructions pre {
  margin-bottom: 0px;
  margin-left: .5em;
}

blockquote.welcomeMessage {
  border-left: 5px solid DarkGreen;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

blockquote.welcomeMessage pre {
  margin-bottom: 0px;
  margin-left: .5em;
}

.toast-container {
  top: 80px !important;
  z-index: 2000;
}

a.disabled {
  filter: brightness(85%);
  pointer-events: none;
  cursor: default;
  text-decoration: none;
}